.table{
    width: 100%;
    font-size: 12px;
    table{
        width: 100%;
        border-collapse: separate;
        border: 1px solid var(--n5);
        border-radius: 12px;
        border-spacing: 0;
        tr{
            background-color: var(--White);
            td{
                height: 48px;
                border-right: 1px solid var(--n5);
                border-bottom: 1px solid var(--n5);
                padding: 8px;
                width: 100px;
                min-height: 120px;
                .filter{
                    display: flex;
                    width: 100%;
                    align-items: center;
                    gap: 4px;
                    .sort{
                        display: flex;
                        cursor: pointer;
                    }
                }
                &.calldate{
                    width: 165px;
                }
                &.duration{
                    width: 155px;
                }
                &.delete{
                    width: 50px;
                }
                &.is_delete_after_pay{
                    width: 110px;
                }
                &.is_reserved{
                    width: 90px;
                }
                &.balance{
                    .wrap{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
                &:nth-last-child(1){
                    border-right: none;
                }
                .roles{
                    width: 100%;
                    display: flex;
                    gap: 12px;
                    flex-direction: column;
                    .moduleWrap{
                        width: 100%;
                        display: flex;
                        gap: 8px;
                        padding: 8px;
                        background-color: var(--LightSweetMint);
                        border-radius: 8px;
                        .roleFild{
                            flex: 1;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 12px;
                            .name{
                                min-width: 50px;
                            }
                            .checkboxField{
                                width: 25px;
                            }
                        } 
                    }
                    .roleWrap{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        padding: 0 8px;
                        .roleFild{
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 12px;
                            .name{
                                width: 170px;
                            }
                            .checkboxField{
                                width: 25px;
                            }
                        }                    
                    }                    
                }
                .rolesHeader{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

            }
        }  
        tbody{
            tr{
                &:nth-last-child(1){
                    td{
                        border-bottom: none;
                    }
                }
                &:nth-child(odd){
                    background-color: var(--BG);
                }                
            }
        }      
    }
    &.empty{
        table{
            width: 100%;
            border-collapse: separate;
            border: 1px solid var(--n5);
            border-radius: 12px;
            border-spacing: 0;
            table-layout: fixed;
            tr{
                td{
                    border-bottom: none;
                }
            }
        }
    }
}
.nodata{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.wrap{
    display: flex;
    position: relative;
    min-width: 185px;
    .input,
    .inputNum {
        width: 100%;
        height: 40px;
        border: 1px solid var(--n1);
        border-radius: 8px;
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        white-space: nowrap;
        background-color: var(--White);
        cursor: pointer;
        outline: none;
        .icon{
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: var(--transition);
        }      
        &.open{
            border: 1px solid var(--SweetMint);
            .icon{
                transform: rotate(180deg);
            }
        }

    }

    .listWrap{
        display: none;
        flex-direction: column;
        position: absolute;
        top: 48px;
        left: 0;
        width: 100%;
        padding: 8px;
        border-radius: 8px;
        border: 1px solid var(--n3);
        overflow: hidden;
        gap: 12px;
        background-color: var(--White);
        z-index: 1;
        &.open{
            display: flex;
        }
        .item{
            display: flex;
            width: 100%;
            height: 24px;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            &.active{
                .checkbox{
                    border: 1px solid var(--Shadow);
                    background-color: var(--Shadow);
                    svg{
                        display: block;
                    }
                }
            }
        }
    }

}
.wrap{
    display: flex;
    width: 100%;
    min-height: 100vh;
    gap: 16px;
    align-items: stretch;
    justify-content: space-between;
    &.padd{
        transition: var(--transition);
        padding: 16px 16px 16px 96px;
        &.open{
            padding: 16px 16px 16px 252px;
        }        
    }
    .sb{
        display: flex;
        width: 64px;
        height: calc(100vh - 32px);
        background-color: var(--SweetMint);
        border-radius: 8px;
        flex-direction: column;
        justify-content: space-between;
        align-self: stretch;
        transition: var(--transition);
        padding: 24px 12px;
        position: fixed;
        left: 16px;
        top: 16px;

        .menuWrap{
            width: 100%;
            flex-direction: column;
            gap: 90px;
            .logo{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 20px;
                img{
                    width: 40px;
                    height: 20px;   
                    transition: var(--transition);                    
                }

            }
        }

        .switcher{
            display: flex;
            width: 40px;
            gap: 8px;
            align-items: center;
            padding: 10px;
            cursor: pointer;
            color: var(--Shadow);
            text-decoration: none;
            transition: var(--transition);
            position: relative;
            overflow: hidden;
    
            .m_icon{
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                svg{
                    width: 20px;
                    height: 20px;
                    path{
                        fill: var(--Shadow);
                    }            
                }
    
            }
            .m_txt{
                font-size: 12px;
                white-space: nowrap;
                opacity: 0;
                transition: var(--transition);
            } 
            .tooltip{
                position: absolute;
                left: 0;
                top: -48px;
                background-color: var(--White);
                border-radius: 8px;
                color: var(--Shadow);
                font-size: 12px;
                padding: 12px;
                z-index: 1;
                white-space: nowrap;
                display: none;
            }
            &:hover{
                background-color: var(--LightSweetMint);
                border-radius: 10px;
                .tooltip{
                    display: block;
                }
            }
            &.open{
                width: 196px;
                .m_txt{
                    opacity: 1;
                }
                .m_icon{
                    transform: rotate(180deg);
                }
            }
        }

        &.open{
            width: 220px;
            .menuWrap{
                .logo{
                    img{
                        width: 118px;  
                        height: 20px;                      
                    }
                }
            }
        }        

    }
    .content{
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 24px;
    }
}
@media screen and (max-width: 767px){
    .wrap{
        overflow: hidden;
        &.padd{
            padding: 16px 16px 16px 16px;
        }
        &.open{
            padding: 16px 16px 16px 16px;
        }
        .sb{
            display: none;
        }
        .content{
            width: 100%;
        }
    }
}
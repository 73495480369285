.wrap{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;

    .menuItem{
        display: flex;
        width: 40px;
        gap: 8px;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        color: var(--Shadow);
        text-decoration: none;
        transition: var(--transition);
        position: relative;

        .m_icon{
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg{
                width: 20px;
                height: 20px;
                path{
                    fill: var(--Shadow);
                }            
            }

        }
        .m_txt{
            font-size: 14px;
            white-space: nowrap;
            opacity: 0;
            display: none;
            transition: var(--transition);
        } 
        .tooltip{
            position: absolute;
            left: 0;
            bottom: -48px;
            background-color: var(--White);
            border-radius: 8px;
            color: var(--Shadow);
            font-size: 12px;
            padding: 12px;
            z-index: 1;
            white-space: nowrap;
            display: none;
        }
        &.open{
            width: 196px;
            .m_txt{
                opacity: 1;
                display: block;
            }
        }
        &:hover{
            background-color: var(--LightSweetMint);
            border-radius: 10px;
            .tooltip{
                display: block;
            }
        }
        &.active{
            background-color: var(--Shadow);
            border-radius: 10px;
            color: var(--White);
            .m_icon{
                svg{
                    path{
                        fill: var(--White);
                    }            
                }
        
            }
        }
    }
}
